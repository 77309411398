import { toast } from "react-toastify";
import Api from '../../../../axios'
import { callLoader } from '../../../../common/actions/app'

let ACTION_GS1_LIST = {
    type: 'GS1_LIST',
    payload: {
        rows: [],
        total: 0,
        pagination: [],
    },
};

let ACTION_GS1_VIEW = {
    type: 'GS1_VIEW',
    payload: {
        gs1: '',
        nickname: '',
        documentType: '',
        document: '',
        name: '',
        email: '',
    },
};

let callGS1ListGet = (filter = {}, pg = 1) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/register/gs1?pg=' + pg).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_GS1_LIST.payload.rows = data.rows
        ACTION_GS1_LIST.payload.total = data.total
        ACTION_GS1_LIST.payload.pagination = data.pagination
        dispatch(ACTION_GS1_LIST)
    })
}

let callGS1ViewGet = (id) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/register/gs1/' + id).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_GS1_VIEW.payload.gs1 = data.gs1
        ACTION_GS1_VIEW.payload.nickname = data.nickname
        ACTION_GS1_VIEW.payload.documentType = data.documentType
        ACTION_GS1_VIEW.payload.document = data.document
        ACTION_GS1_VIEW.payload.name = data.name
        ACTION_GS1_VIEW.payload.email = data.email
        dispatch(ACTION_GS1_VIEW)
    })
}

let callGS1Post = (data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.post('/admin/register/gs1', data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callGS1Put = (id, data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.put('/admin/register/gs1/' + id, data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callGS1ClearView = () => (dispatch) => {
    ACTION_GS1_VIEW.payload.gs1 = ''
    ACTION_GS1_VIEW.payload.nickname = ''
    ACTION_GS1_VIEW.payload.documentType = ''
    ACTION_GS1_VIEW.payload.document = ''
    ACTION_GS1_VIEW.payload.name = ''
    ACTION_GS1_VIEW.payload.email = ''
    dispatch(ACTION_GS1_VIEW)
}

let callGS1Delete = (id, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.delete('/admin/register/gs1/' + id).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

export { callGS1ListGet, callGS1ViewGet, callGS1Post, callGS1Put, callGS1Delete, callGS1ClearView, ACTION_GS1_LIST, ACTION_GS1_VIEW }