import { connect } from 'react-redux'
import { Route, Routes } from 'react-router'
import GS1List from './GS1List'
import GS1Edit from './GS1Edit'
import { useEffect, useState } from 'react'
import Card from '../../../../../common/containers/Card'

let GS1Page = ({setBreadcrumbs}) => {
    const [pageAttr, setPageAttr] = useState({})

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Dashboard', href: '/' },
            { label: 'Cadastro - Algodoeiras', active: true }
        ])
    }, [setBreadcrumbs])

    return (
        <Card {...pageAttr} showClose={false}>
            <Routes>
                <Route path='/edit/:id' element={<GS1Edit setPageAttr={setPageAttr} />} />
                <Route path='/add' element={<GS1Edit setPageAttr={setPageAttr} />} />
                <Route index element={<GS1List setPageAttr={setPageAttr} />} />
            </Routes>
        </Card>
    )
}

export default connect()(GS1Page)