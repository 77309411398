import CustomerPage from "../containers/Customer/CustomerPage"
import GS1Page from "../containers/GS1/GS1Page"
import ProducerPage from "../containers/Producer/ProducerPage"
import UserPage from "../containers/User/UserPage"

let RegisterRoutes = [
    {
        path: 'register/user/*',
        container: UserPage,
    },
    {
        path: 'register/gs1/*',
        container: GS1Page,
    },
    {
        path: 'register/producers/*',
        container: ProducerPage,
    },
    {
        path: 'register/customer/*',
        container: CustomerPage,
    },
]

export default RegisterRoutes