import { Link } from "react-router-dom"
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useEffect } from "react";

let Dashboard = ({ setBreadcrumbs }) => {
    useEffect(() => {
        setBreadcrumbs([
            { label: 'Dashboard', active: true }
        ])
    }, [setBreadcrumbs])

    return (
        <div className="container-fluid">
            <div className="card">
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2 className="card-title">Dashboard</h2>
                            <p className="card-text">Bem vindo</p>
                        </div>
                    </div>
                    <ul className="nav nav-tabs mt-4">
                        <li className="nav-item">
                            <Link className='nav-link active' to='/admin'>Informações</Link>
                        </li>
                    </ul>
                    <div className="tabcontent-border">

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)