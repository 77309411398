import { ACTION_CUSTOMER_LIST, ACTION_CUSTOMER_SELECT, ACTION_CUSTOMER_VIEW } from "../actions/customers";
import { ACTION_GS1_LIST, ACTION_GS1_VIEW } from "../actions/gs1";
import { ACTION_PRODUCER_LIST, ACTION_PRODUCER_VIEW } from "../actions/producers";
import { ACTION_USER_LIST, ACTION_USER_VIEW } from "../actions/users";

let initialReducer = {
    users: {
        list: {
            rows: [],
            total: 0,
            pagination: [],
        },
        view: {
            name: '',
            email: '',
        },
    },
    gs1: {
        list: {
            rows: [],
            total: 0,
            pagination: [],
        },
        view: {
            gs1: '',
            nickname: '',
            documentType: '',
            document: '',
            name: '',
            email: '',
        },
    },
    producers: {
        list: {
            rows: [],
            total: 0,
            pagination: [],
        },
        view: {
            nickname: '',
            documentType: '',
            document: '',
            name: '',
            email: '',
        },
    },
    customers: {
        list: {
            rows: [],
            total: 0,
            pagination: [],
        },
        view: {
            name: '',
        },
        select: [],
    },
}

let RegisterReducers = (state = initialReducer, action) => {
    switch (action.type) {
        case ACTION_USER_LIST.type:
            return {
                ...state,
                users: {
                    ...state.users,
                    list: {
                        rows: action.payload.rows,
                        total: action.payload.total,
                        pagination: action.payload.pagination
                    },
                }
            };
        case ACTION_USER_VIEW.type:
            return {
                ...state,
                users: {
                    ...state.users,
                    view: {
                        name: action.payload.name,
                        email: action.payload.email,
                    },
                },
            };
        case ACTION_GS1_LIST.type:
            return {
                ...state,
                gs1: {
                    ...state.gs1,
                    list: {
                        rows: action.payload.rows,
                        total: action.payload.total,
                        pagination: action.payload.pagination
                    },
                }
            };
        case ACTION_GS1_VIEW.type:
            return {
                ...state,
                gs1: {
                    ...state.gs1,
                    view: {
                        gs1: action.payload.gs1,
                        nickname: action.payload.nickname,
                        documentType: action.payload.documentType,
                        document: action.payload.document,
                        name: action.payload.name,
                        email: action.payload.email,
                    },
                },
            };
        case ACTION_PRODUCER_LIST.type:
            return {
                ...state,
                producers: {
                    ...state.producers,
                    list: {
                        rows: action.payload.rows,
                        total: action.payload.total,
                        pagination: action.payload.pagination
                    },
                }
            };
        case ACTION_PRODUCER_VIEW.type:
            return {
                ...state,
                producers: {
                    ...state.producers,
                    view: {
                        nickname: action.payload.nickname,
                        documentType: action.payload.documentType,
                        document: action.payload.document,
                        name: action.payload.name,
                        email: action.payload.email,
                    },
                },
            };
        case ACTION_CUSTOMER_LIST.type:
            return {
                ...state,
                customers: {
                    ...state.customers,
                    list: {
                        rows: action.payload.rows,
                        total: action.payload.total,
                        pagination: action.payload.pagination
                    },
                }
            };
        case ACTION_CUSTOMER_VIEW.type:
            return {
                ...state,
                customers: {
                    ...state.customers,
                    view: {
                        name: action.payload.name,
                    },
                },
            };
        case ACTION_CUSTOMER_SELECT.type:
            return {
                ...state,
                customers: {
                    ...state.customers,
                    select: action.payload
                },
            };
        default:
            return state;
    }
}

export default RegisterReducers