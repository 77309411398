import { useCallback, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import logo from './../../../resources/img/logo.png'
import './../styles/Menu.scss'
import { useEffect } from "react";
import Swal from 'sweetalert2'
import { callAuthDelete } from "../actions/app";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const Menu = ({ callAuthDelete }) => {
    const [width, setWidth] = useState(76);
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const [urlCurrent, setUrlCurrent] = useState(location.pathname);

    const logout = () => {
        Swal.fire({
            title: 'Deseja se desconectar do sistema?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                callAuthDelete()
            }
        })
    }

    let toggleMenu = useCallback(() => {
        if (width === 320) {
            setWidth(76);
            setOpen(false);
        } else {
            setWidth(320);
            setOpen(true);
        }
    }, [width, setWidth, setOpen]);

    let menu = [
        {
            label: 'Página inicial',
            link: '/',
            icon: 'fas fa-home',
            name: 'home',
            new: false
        },
        {
            label: 'Usuários',
            link: '/register/user',
            icon: 'fas fa-users',
            name: 'users',
        },
        {
            label: 'GS1',
            link: '/register/gs1',
            icon: 'fas fa-building',
            name: 'gs1',
        },
        {
            label: 'Produtores',
            link: '/register/producers',
            icon: 'fas fa-users',
            name: 'producers',
        },
        {
            label: 'Desconectar',
            icon: 'fas fa-door-open',
            name: 'customers',
            color: '#990000',
            action: logout
        },
    ]

    useEffect(() => {
        if (open && location.pathname !== urlCurrent) {
            toggleMenu();
        }

        setUrlCurrent(location.pathname);
    }, [location, open, urlCurrent, setUrlCurrent, toggleMenu]);

    return (
        <>
            <div className={'navigation-overlay ' + (open ? 'opened' : 'closed')} onClick={_ => toggleMenu()} />
            <div className='wrapper-navigation'>
                <nav className={'navigation ' + (open ? 'opened' : 'closed')} style={{ width: width + 'px' }}>
                    <div className='menu-toggle' onClick={_ => toggleMenu()}>
                        {open ? <i className="fas fa-chevron-left text-white" /> : <i className='fas fa-chevron-right text-white' />}
                    </div>
                    <div className='logo'>
                        <Link to='/' onClick={_ => toggleMenu()}><img src={logo} alt='Logo' /><span className='name'>CONSOLE</span></Link>
                    </div>
                    <ul className='menu'>
                        {menu.map((item, index) => (
                            <li key={index}>
                                {item?.link ?
                                    <Link className='link' to={item.link}><span className='icon'><i className={item.icon}></i></span><span className='label'>{item.label}</span></Link> :
                                    <Link className='link' to={false} onClick={_ => item.action()}><span className='icon'><i className={item.icon} style={{color: item?.color}}></i></span><span className='label' style={{color: item?.color}}>{item.label}</span></Link>}
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </>
    )
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            callAuthDelete
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(Menu)