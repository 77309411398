import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import { bindActionCreators } from "redux"
import { callGS1ViewGet, callGS1Post, callGS1Put } from "../../actions/gs1"
import Sidebar from "../../../../../common/containers/Sidebar"
import Card from "../../../../../common/containers/Card"
import InputMask from "../../../../../common/containers/InputMask"

let GS1Edit = ({ methods: { callGS1Post, callGS1ViewGet, callGS1Put }, view }) => {
    const params = useParams()
    const [id] = useState(!params.id ? '' : params.id)

    const [gs1, setGS1] = useState('')
    const [nickname, setNickname] = useState('')
    const [documentType, setDocumentType] = useState('')
    const [document, setDocument] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const navigateTo = useNavigate()

    const [pageAttr, setPageAttr] = useState({})

    useEffect(() => {
        let tabs
        if (!id) {
            tabs = [
                {
                    active: true,
                    to: '/register/gs1/add',
                    label: 'Adicionar'
                }
            ]
        } else {
            callGS1ViewGet(id)
            tabs = [
                {
                    active: true,
                    to: '/register/gs1/edit/' + id,
                    label: 'Editar'
                }
            ]
        }

        setPageAttr({
            title: 'Algodoeira - ' + (!id ? 'Novo' : 'Editar'),
            caption: 'Preencha os campos para inserir as informações',
            btns: [],
            tabs: tabs
        })
    }, [setPageAttr, id, callGS1ViewGet])

    useEffect(() => {
        setGS1(view.gs1)
        setNickname(view.nickname)
        setDocumentType(view.documentType)
        setDocument(view.document)
        setName(view.name)
        setEmail(view.email)
    }, [view])

    const finishedSubmit = () => {
        setGS1('')
        setNickname('')
        setDocumentType('')
        setDocument('')
        setName('')
        setEmail('')
        navigateTo('/register/gs1')
    }

    const submit = (e) => {
        e.preventDefault()

        let data = {
            gs1,
            nickname,
            documentType,
            document,
            name,
            email
        }

        if (!id) {
            callGS1Post(data, () => finishedSubmit())
        } else {
            callGS1Put(id, data, () => finishedSubmit())
        }
    }

    return (
        <Sidebar pWidth="50%">
            <Card {...pageAttr} showClose={false}>
                <form onSubmit={submit}>
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-row">
                                <div className="col-md-6 form-group">
                                    <label className="required">GS1:</label>
                                    <input type="text" className="form-control" value={gs1} onChange={e => setGS1(e.target.value)} />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label className="required">Nome da Algodoeira:</label>
                                    <input type="text" className="form-control" value={nickname} onChange={e => setNickname(e.target.value)} />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Tipo de Documento:</label>
                                    <select className="form-control" onChange={e => { setDocumentType(e.target.value); setDocument('') }}>
                                        <option value="">Selecione</option>
                                        <option value="F" selected={documentType === 'F'}>Pessoa Física</option>
                                        <option value="J" selected={documentType === 'J'}>Pessoa Jurídica</option>
                                    </select>
                                </div>
                                {documentType === 'F' && (
                                    <div className="col-md-6 form-group">
                                        <label>CPF:</label>
                                        <InputMask mask="cpf" value={document} onChange={v => setDocument(v)} />
                                    </div>
                                )}
                                {documentType === 'J' && (
                                    <div className="col-md-6 form-group">
                                        <label>CNPJ:</label>
                                        <InputMask mask="cnpj" value={document} onChange={v => setDocument(v)} />
                                    </div>
                                )}
                                {documentType && (
                                    <>
                                        <div className="col-md-6 form-group">
                                            <label>{documentType === 'F' ? 'Nome Completo' : 'Razão Social:'}</label>
                                            <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>E-mail:</label>
                                            <input type="text" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="clearfix text-left mt-3">
                        <button className="btn btn-primary" type="submit"><i className="mr-1 fas fa-save fa-white"></i>Salvar</button>
                        <Link to='/register/gs1' className='btn btn-secondary ml-2'>Cancelar</Link>
                    </div>
                </form>
            </Card>
        </Sidebar>
    )
}

const mapStateToProps = ({ register: { gs1: { view } } }) => ({
    view: view,
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callGS1ViewGet,
            callGS1Post,
            callGS1Put,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(GS1Edit)